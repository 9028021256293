html {
  /* font-size: 625%; */
  /*10 ÷ 16 × 100% = 62.5%*/
  font-size: 100px;
}
/* 计算方法是 320/750,得出比率，750是设计稿的宽度*/
/* @media screen and (min-width: 320px) {html{font-size:42.66px;}}
  @media screen and (min-width: 384px) {html{font-size:51.2px;}}
  @media screen and (min-width: 480px) {html{font-size:64px;}}
  @media screen and (min-width: 640px) {html{font-size:85.33px;}} */
/* @media screen and (min-width: 50px) {html{font-size:13.3333px;}}
  @media screen and (min-width: 100px) {html{font-size:26.6667px;}}
  @media screen and (min-width: 140px) {html{font-size:37.3333px;}}
  @media screen and (min-width: 160px) {html{font-size:42.6667px;}}
  @media screen and (min-width: 180px) {html{font-size:48px;}}
  @media screen and (min-width: 200px) {html{font-size:53.3333px;}}
  @media screen and (min-width: 240px) {html{font-size:64px;}}
  @media screen and (min-width: 280px) {html{font-size:74.6667px;}}
  @media screen and (min-width: 320px) {html{font-size:85.3333px;}}
  @media screen and (min-width: 352px) {html{font-size:93.8667px;}}
  @media screen and (min-width: 360px) {html{font-size:96px;}}
  @media screen and (min-width: 375px) {html{font-size:100px;}}
  @media screen and (min-width: 400px) {html{font-size:106.6667px;}}
  @media screen and (min-width: 414px) {html{font-size:110.4px;}}
  @media screen and (min-width: 440px) {html{font-size:117.3333px;}}
  @media screen and (min-width: 480px) {html{font-size:128px;}}
  @media screen and (min-width: 520px) {html{font-size:138.6667px;}}
  @media screen and (min-width: 560px) {html{font-size:149.3333px;}}
  @media screen and (min-width: 600px) {html{font-size:160px;}}
  @media screen and (min-width: 640px) {html{font-size:170.6667px;}}
  @media screen and (min-width: 680px) {html{font-size:181.3333px;}}
  @media screen and (min-width: 720px) {html{font-size:192px;}}
  @media screen and (min-width: 750px) {html{font-size:200px;}}
  @media screen and (min-width: 760px) {html{font-size:202.6667px;}}
  @media screen and (min-width: 800px) {html{font-size:213.3333px;}}
  @media screen and (min-width: 960px) {html{font-size:256px;}} */
body {
  margin: 0;
  font-family: "Microsoft YaHei", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.14rem;
  -webkit-text-size-adjust: 100%!important;
  -webkit-text-size-adjust: none !important;
  /* -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); */
  /* h5页面在安卓手机浏览器上不能上下移动的问题 */
  touch-action: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
