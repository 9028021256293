.App {
  text-align: center;
}
.ant-btn-primary {
  background-color: #17c97b;
  border-color: #17c97b;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #17c97b;
  border-color: #17c97b;
}
.ant-btn-link {
  color: #17c97b;
}
.ant-btn-link:active,
.ant-btn-link.active {
  color: #17c97b;
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #17c97b;
}
.ant-back-top-icon {
  background-size: 100%;
}
.opacity5 {
  opacity: 0.5;
}
