/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.OrderTrack .ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title {
  height: 0.05rem !important;
  line-height: 0.05rem !important;
}
.OrderTrack #root .ant-layout {
  background: #fff;
}
.OrderTrack .creditAmount {
  color: #F98940;
}
.OrderTrack .paymethod-list {
  display: inline-block;
  vertical-align: top;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-tail {
  left: 0.11rem !important;
  padding: 0.25rem 0 0.03rem !important;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-icon {
  background: #D1D1D1 !important;
  width: 0.22rem;
  height: 0.22rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-icon .ant-steps-icon {
  top: -0.005rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-title {
  font-weight: 700;
  font-size: 0.16rem;
  line-height: 0.225rem;
  color: rgba(0, 0, 0, 0.45);
  padding-right: 0.12rem;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description {
  font-size: 0.13rem;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description .revoke > div {
  text-decoration: line-through;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description > div > div {
  margin-bottom: 0.08rem;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description > div .title-time {
  margin-top: -0.2rem;
  text-decoration: none;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description > div .title-time2 {
  padding-left: 0.45rem;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description > div .title-time3 {
  padding-left: 0.61rem;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description > div .title-time4 {
  padding-left: 0.78rem;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description > div .title-time5 {
  padding-left: 0.95rem;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description > div .title-time6 {
  padding-left: 1.12rem;
}
.OrderTrack .ant-steps-item-custom .ant-steps-item-content .ant-steps-item-description > div .title-time7 {
  padding-left: 1.29rem;
}
.OrderTrack .ant-steps-item-custom.ant-steps-item-active .ant-steps-item-icon {
  background-color: #1D9E60 !important;
  border-color: #1D9E60 !important;
}
.OrderTrack .ant-steps-item-custom.ant-steps-item-active .ant-steps-item-icon .ant-steps-icon {
  color: #fff !important;
}
.OrderTrack .ant-steps-item-custom.ant-steps-item-active .ant-steps-item-content .ant-steps-item-title {
  color: #1D9E60 !important;
}
.OrderTrack #root .ant-layout {
  background: #fff;
}
.OrderTrack .layout .ant-layout {
  background: red;
}
.OrderTrack .layout .ant-layout-sider {
  width: 1.28rem;
  max-width: 1.28rem;
  min-width: 1.28rem;
  background: #fff;
  display: none;
}
.OrderTrack .layout .ant-layout-sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.OrderTrack .headerList {
  margin-bottom: 0.02rem;
}
.OrderTrack .tabsCard .ant-card-head {
  padding: 0 0.08rem;
}
.OrderTrack .submitBtn {
  text-align: right;
  margin: 0.05rem 0;
}
.OrderTrack .extraContent .title {
  padding: 0.03rem 0;
  text-align: left;
}
.OrderTrack .extraContent .btn {
  padding: 0.03rem 0;
  text-align: left;
}
.OrderTrack .extraContent .amount .deficiency {
  text-align: left;
}
.OrderTrack .extraContent .amount .total {
  text-align: left;
}
.OrderTrack a {
  color: #1D9E60;
}
.OrderTrack a:active {
  color: #389e0d;
}
.OrderTrack a:hover {
  color: #73d13d;
}
.OrderTrack .ant-layout-content {
  margin: 0 !important;
}
.OrderTrack .ant-card-body {
  padding: 0.09rem 0.12rem 0.12rem;
}
